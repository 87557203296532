.App {
  text-align: center;
}


/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/


/* @import url("all.min.css"); */



i {
  -moz-transition: border-color 0.2s ease-in-out;
  -webkit-transition: border-color 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  color: inherit;
  outline: 0;
  text-decoration: none;
}

i:hover {
  border-color: transparent;
}

/* Icon */

.icon {
  text-decoration: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 6 Pro", serif;
  font-weight: 400;
}

.icon > .label {
  display: none;
}

.icon.solid:before {
  font-weight: 900;
}


/* Wrapper */

@-moz-keyframes wrapper {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes wrapper {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes wrapper {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes wrapper {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.wrapper {
  -moz-animation: wrapper 3s forwards;
  -webkit-animation: wrapper 3s forwards;
  -ms-animation: wrapper 3s forwards;
  animation: wrapper 3s forwards;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

/* BG */
.bg {
  background-size: 2250px auto;
  width: 6750px;
}
.bg {
  -moz-animation: bg 60s linear infinite;
  -webkit-animation: bg 60s linear infinite;
  -ms-animation: bg 60s linear infinite;
  animation: bg 60s linear infinite;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* Set your background with this */
  background: #348cb2 url("../../public/assets/css/images/bg.jpg") repeat-x bottom left;
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
}

@-moz-keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }
}

@-webkit-keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }
}

@-ms-keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }
}

@keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }
}



/* Overlay */

@-moz-keyframes overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overlay {
  -moz-animation: overlay 1.5s 1.5s forwards;
  -webkit-animation: overlay 1.5s 1.5s forwards;
  -ms-animation: overlay 1.5s 1.5s forwards;
  animation: overlay 1.5s 1.5s forwards;
  background-attachment: fixed, fixed;
  background-image: url("../../public/assets/css/images/overlay-pattern.png"), url("../../public/assets/css/images/overlay.svg");
  background-position: top left, center center;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

/* Main */

.main {
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
}

.main:before {
  content: "";
  display: inline-block;
  height: 100%;
  margin-right: 0;
  vertical-align: middle;
  width: 1px;
}

/* Header */

@-moz-keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-ms-keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-moz-keyframes nav-icons {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes nav-icons {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-ms-keyframes nav-icons {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes nav-icons {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.header {
  -moz-animation: header 1s 2.25s forwards;
  -webkit-animation: header 1s 2.25s forwards;
  -ms-animation: header 1s 2.25s forwards;
  animation: header 1s 2.25s forwards;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  cursor: default;
  display: inline-block;
  opacity: 0;
  position: relative;
  text-align: center;
  top: -1em;
  vertical-align: middle;
  width: 90%;
}


.header .title {
  font-size: 2.35em;
  font-weight: 900;
  letter-spacing: -0.035em;
  line-height: 1em;
}


.header ul {
  margin: 1.5em 0 0 0;
}

.header ul li {
  -moz-animation: nav-icons 0.5s ease-in-out forwards;
  -webkit-animation: nav-icons 0.5s ease-in-out forwards;
  -ms-animation: nav-icons 0.5s ease-in-out forwards;
  animation: nav-icons 0.5s ease-in-out forwards;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: inline-block;
  height: 5.35em;
  line-height: 5.885em;
  opacity: 0;
  position: relative;
  top: 0;
  width: 5.35em;
}

.header ul li:nth-child(1) {
  -moz-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.header ul li:nth-child(2) {
  -moz-animation-delay: 2.75s;
  -webkit-animation-delay: 2.75s;
  -ms-animation-delay: 2.75s;
  animation-delay: 2.75s;
}

.header ul li:nth-child(3) {
  -moz-animation-delay: 3s;
  -webkit-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s;
}

.header ul li:nth-child(4) {
  -moz-animation-delay: 3.25s;
  -webkit-animation-delay: 3.25s;
  -ms-animation-delay: 3.25s;
  animation-delay: 3.25s;
}

.header ul li:nth-child(5) {
  -moz-animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
  -ms-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

.header ul li:nth-child(6) {
  -moz-animation-delay: 3.75s;
  -webkit-animation-delay: 3.75s;
  -ms-animation-delay: 3.75s;
  animation-delay: 3.75s;
}

.header ul li:nth-child(7) {
  -moz-animation-delay: 4s;
  -webkit-animation-delay: 4s;
  -ms-animation-delay: 4s;
  animation-delay: 4s;
}

.header ul li:nth-child(8) {
  -moz-animation-delay: 4.25s;
  -webkit-animation-delay: 4.25s;
  -ms-animation-delay: 4.25s;
  animation-delay: 4.25s;
}

.header ul li:nth-child(9) {
  -moz-animation-delay: 4.5s;
  -webkit-animation-delay: 4.5s;
  -ms-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

.header ul li:nth-child(10) {
  -moz-animation-delay: 4.75s;
  -webkit-animation-delay: 4.75s;
  -ms-animation-delay: 4.75s;
  animation-delay: 4.75s;
}

.header ul i {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  border: 0;
  display: inline-block;
}

.header ul i:before {
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 100%;
  border: solid 1px #fff;
  display: block;
  font-size: 1.75em;
  height: 2.5em;
  line-height: 2.5em;
  position: relative;
  text-align: center;
  top: 0;
  width: 2.5em;
}

.header ul i:hover {
  font-size: 1.1em;
}

.header ul i:hover:before {
  background-color: rgba(255, 255, 255, 0.175);
  color: #fff;
}

.header  ul i:active {
  font-size: 0.95em;
  background: none;
}

.header ul i:active:before {
  background-color: rgba(255, 255, 255, 0.35);
  color: #fff;
}

.header ul i span {
  display: none;
}

/* Footer */

.footer {
  /*background-image: -moz-linear-gradient(*/
  /*  top,*/
  /*  rgba(0, 0, 0, 0),*/
  /*  rgba(0, 0, 0, 0.5) 75%*/
  /*);*/
  /*background-image: -webkit-linear-gradient(*/
  /*  top,*/
  /*  rgba(0, 0, 0, 0),*/
  /*  rgba(0, 0, 0, 0.5) 75%*/
  /*);*/
  /*background-image: -ms-linear-gradient(*/
  /*  top,*/
  /*  rgba(0, 0, 0, 0),*/
  /*  rgba(0, 0, 0, 0.5) 75%*/
  /*);*/
  /*background-image: linear-gradient(*/
  /*  top,*/
  /*  rgba(0, 0, 0, 0),*/
  /*  rgba(0, 0, 0, 0.5) 75%*/
  /*);*/
  bottom: 0;
  cursor: default;
  height: 6em;
  left: 0;
  line-height: 8em;
  position: absolute;
  text-align: center;
  width: 100%;
}

/* Wide */

@media screen and (max-width: 1680px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 13pt;
  }

  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-1500px, 0, 0);
      -webkit-transform: translate3d(-1500px, 0, 0);
      -ms-transform: translate3d(-1500px, 0, 0);
      transform: translate3d(-1500px, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-1500px, 0, 0);
      -webkit-transform: translate3d(-1500px, 0, 0);
      -ms-transform: translate3d(-1500px, 0, 0);
      transform: translate3d(-1500px, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-1500px, 0, 0);
      -webkit-transform: translate3d(-1500px, 0, 0);
      -ms-transform: translate3d(-1500px, 0, 0);
      transform: translate3d(-1500px, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-1500px, 0, 0);
      -webkit-transform: translate3d(-1500px, 0, 0);
      -ms-transform: translate3d(-1500px, 0, 0);
      transform: translate3d(-1500px, 0, 0);
    }
  }

  #bg {
    background-size: 1500px auto;
    width: 4500px;
  }
}

/* Normal */

@media screen and (max-width: 1280px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }
  }

  #bg {
    background-size: 750px auto;
    width: 2250px;
  }
}

/* Mobile */

@media screen and (max-width: 736px) {
  /* Basic */

  body {
    min-width: 320px;
  }

  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }

  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }
  }

  #bg {
    background-size: 300px auto;
    width: 900px;
  }

  /* Header */



  .header p {
    font-size: 1em;
  }

  .header ul {
    font-size: 1em;
    padding: 0 1em;
  }

  .header ul i:hover {
    font-size: 1em;
  }

  .header ul i:active {
    font-size: 1em;
  }
}

/* Mobile (Portrait) */

@media screen and (max-width: 480px) {
  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }
  }

  #bg {
    background-size: 412.5px auto;
    width: 1237.5px;
  }




}
